import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import CompanyDataService from '../../services/company.service';
import MissionDataService from '../../services/mission.service';

export default function EntrepriseMissionList(props) {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const [tabClass, setTabClass] = useState('profil-container-divider-2');
    const currentUser = useAuthState();
    const [entreprise, setEntreprise] = useState([]);
    const [editMode, setEditMode] = useState(0);
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const [year, setYear] = useState(0);
    const isAssociation = () => {
        if (currentUser.function === 'Compte association') {
            return true;
        }
        if (currentUser.function === 'Président') {
            return true;
        }
        return false;
    };

    useEffect(() => {
        let objectData = {
            id: null,
            name: 'Nom non communiqué',
            siret: 'Pas de numéro de SIRET',
            email: 'Aucune adresse email connue',
            phone: 'Pas de téléphone',
            started_at: '3999-01-01',
            contact: 'Pas de contact connu',
        };
        let key = null;
        if (id !== '0' && id !== 'me') {
            key = id;
        }
        if (currentUser.id !== '0' && id == 'me') {
            key =
                'SELECT Company AS C JOIN Person AS P ON P.org_id = C.id WHERE P.id = ' +
                currentUser.id;
        }
        if (key) {
            CompanyDataService.get(key).then(response => {
                objectData = {
                    ...objectData,
                    ...response,
                };

                setEntreprise(objectData);

                key = 'SELECT Mission WHERE company_id = ' + objectData.id;

                if (isAssociation()) {
                    key =
                        'SELECT Mission WHERE company_id = ' +
                        objectData.id +
                        ' AND M.student_org_id = ' +
                        currentUser.org_id;
                }

                if (year > 0) {
                    key +=
                        ' AND (YEAR(end_date) = ' + year + ' OR YEAR(start_date) = ' + year + ')';
                }

                const fields =
                    'id, name, ref, status, display_status, start_date, end_date, je, price_ht, company_id_friendlyname';
                MissionDataService.getAll(key, fields).then(response => {
                    setData(response);
                });
            });
        }
    }, [year]);

    const handleTab = className => {
        setTabClass(className);
    };

    const columns = [
        {
            name: 'Référence',
            cell: row => (
                <Link className='list-table-link' to={'/mission/' + row.id + '/profil'}>
                    {row.ref}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.ref < b.ref) {
                    return -1;
                }
                if (a.ref > b.ref) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Début',
            selector: row => (
                <span>
                    {moment(row.start_date).format('DD-MM-')}
                    {(year == '0' || moment(row.start_date).format('YYYY') != year) && (
                        <span>{moment(row.start_date).format('YYYY')}</span>
                    )}
                    {year > 0 && moment(row.start_date).format('YYYY') == year && (
                        <span className='bold'>{moment(row.start_date).format('YYYY')}</span>
                    )}
                </span>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.start_date < b.start_date) {
                    return -1;
                }
                if (a.start_date > b.start_date) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Fin',
            selector: row => (
                <span>
                    {moment(row.end_date).format('DD-MM-')}
                    {(year == '0' || moment(row.end_date).format('YYYY') != year) && (
                        <span>{moment(row.end_date).format('YYYY')}</span>
                    )}
                    {year > 0 && moment(row.end_date).format('YYYY') == year && (
                        <span className='bold'>{moment(row.end_date).format('YYYY')}</span>
                    )}
                </span>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.end_date < b.end_date) {
                    return -1;
                }
                if (a.end_date > b.end_date) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Etudiant',
            cell: row => (
                <Link className='list-table-link' to={'/etudiant/' + row.student_id + '/profil'}>
                    {row.student}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student < b.student) {
                    return -1;
                }
                if (a.student > b.student) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Type',
            selector: row =>
                row.mission_type === 'normal'
                    ? 'Convention temps complet'
                    : row.mission_type === 'parttime'
                    ? 'Convention temps partiel'
                    : 'Convention HMONP',
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.mission_type < b.mission_type) {
                    return -1;
                }
                if (a.mission_type > b.mission_type) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Statut',
            selector: row => row.display_status,
            cell: row =>
                row.display_status === 'En cours' ? (
                    <span className='fluo-value'>{row.display_status}</span>
                ) : row.display_status === 'En création' ? (
                    <span className='green-value'>{row.display_status}</span>
                ) : (
                    <span className='gray-value'>{row.display_status}</span>
                ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.display_status < b.display_status) {
                    return -1;
                }
                if (a.display_status > b.display_status) {
                    return 1;
                }
                return 0;
            },
        },
    ];

    const convertArrayOfObjectsToCSV = array => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(filteredItems[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    const ExpandedMission = ({ data }) => {
        const filteredTerms = data.terms_list.filter(term => term.facture_id !== '0');
        return (
            <pre>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Titre mission : </span>
                        <span className='list-mission-value'>{data.name}</span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Tarif JE HT : </span>
                        <span className='list-mission-value'>{data.price_ht} €</span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Total JE : </span>
                        <span className='list-mission-value'>{data.je}</span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Echéance : </span>
                        <span className='list-mission-value'>
                            {filteredTerms.length} / {data.terms_list.length}
                        </span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Association : </span>
                        <span className='list-mission-value'>
                            {data && data.student_org_id > 0 ? (
                                <Link
                                    className='list-table-link'
                                    to={'/association/' + data.student_org_id + '/profil'}>
                                    {data.student_org_name}
                                </Link>
                            ) : (
                                "Pas d'association"
                            )}
                        </span>
                    </div>
                </div>
            </pre>
        );
    };

    if (Object.keys(entreprise).length === 0) {
        return (
            <div class='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <div
            className='backend-container'
            onClick={() => {
                if (editMode === 1) {
                    setEditMode(0);
                }
            }}>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>{entreprise && entreprise.name}</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4 list-container-tabs'>
                    <Link
                        to={'/entreprise/' + id + '/profil'}
                        className='profil-tabs'
                        // onMouseOver={() => handleTab('profil-container-divider-1')}
                        // onMouseOut={() => handleTab('profil-container-divider-2')}
                    >
                        Profil
                    </Link>
                    <Link
                        to={'/entreprise/' + id + '/mission'}
                        className='profil-tabs'
                        // onMouseOver={() => handleTab('profil-container-divider-2')}
                    >
                        Missions
                    </Link>
                    <Link
                        to={'/entreprise/' + id + '/document'}
                        className='profil-tabs'
                        // onMouseOver={() => handleTab('profil-container-divider-3')}
                        // onMouseOut={() => handleTab('profil-container-divider-2')}
                    >
                        Documents
                    </Link>
                </div>
                <div className='list-container-searchbar searchbar-with-button'>
                    <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                    <input
                        id='search'
                        className='list-container-searchinput'
                        type='text'
                        placeholder='Rechercher'
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                    />
                </div>
                {editMode < 2 && (
                    <div className='profil-container-wipbutton'>
                        <span className='list-container-addlabel'>
                            <a
                                onClick={() => {
                                    editMode === 1 ? setEditMode(0) : setEditMode(1);
                                }}>
                                +
                            </a>
                        </span>
                    </div>
                )}
                {editMode == 1 && (
                    <div className='more_actions'>
                        <div
                            className='more_actions_action'
                            onClick={() => {
                                downloadCSV(filteredItems);
                            }}>
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <div className='more_actions_item'>Exporter</div>
                        </div>
                        <div className='more_actions_action' onClick={() => setEditMode(1)}>
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <div className='more_actions_item disabled'>Filtrer</div>
                        </div>
                    </div>
                )}
            </div>
            <div className='list-flex-container'>
                <div className={tabClass} />
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    <p className='list-container-subtitle list-tab-subtitle'>
                        Liste des missions proposées par l'entreprise.
                    </p>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child profil-flex-container'>
                    <div className='profil-flex-child'>
                        <div className='profil-label'>
                            <label htmlFor='year'>Année</label>
                        </div>
                    </div>
                    <div className='profil-flex-child'>
                        <select
                            id='year'
                            className='profil-value'
                            onChange={e => setYear(e.target.value)}>
                            <option value='0'>---- Choisir une année ----</option>
                            <option value='2022'>2022</option>
                            <option value='2023'>2023</option>
                            <option value='2024'>2024</option>
                            <option value='2025'>2025</option>
                        </select>
                    </div>
                </div>
                <div className='list-flex-child profil-flex-container'>
                    <div className='profil-flex-child'></div>
                    <div className='profil-flex-child'></div>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucune mission trouvée.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={ExpandedMission}
                    />
                </div>
            </div>
        </div>
    );
}
