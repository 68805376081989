import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Form from '../Utils/Form';
import Input2 from '../Utils/Input2';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import AttachmentDataService from '../../services/attachment.service';
import OrganizationDataService from '../../services/organization.service';

export default function JobersComptaExport(props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [associations, setAssociations] = useState([]);
    const currentUser = useAuthState();
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const onSubmit = e => {
        e.preventDefault();
    };
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const [assoId, setAssoId] = useState(0);
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [editMode, setEditMode] = useState(0);

    useEffect(() => {
        OrganizationDataService.getAll(
            'SELECT Association',
            'id, friendlyname, school_name, started_at'
        )
            .then(response => {
                setAssociations(response);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des associations :', error);
                setLoading(false);
            });
    }, [loading]);

    useEffect(() => {
        let key =
            'SELECT Attachment WHERE item_class = "Association" AND type IN ("JOURNAL_BV", "BANQUE_DECAISSEMENT", "JOURNAL_VENTE", "BANQUE_ENCAISSEMENT")';
        // let key = 'SELECT Attachment WHERE item_class = "Association"';

        if (assoId > 0) {
            key += ' AND item_id = ' + assoId;
        }

        if (month != '' && month != '00') {
            key += ' AND temp_id LIKE "%' + month + '%"';
            // key += ' AND  MONTH(creation_date) = ' + month;
        }

        if (year > 0) {
            key += ' AND temp_id LIKE "%' + year + '%"';
            // key += ' AND YEAR(creation_date) = ' + year;
        }

        if (key) {
            AttachmentDataService.getAll(key, []).then(response => {
                setData(response);
            });
        }
    }, [assoId, year, month]);

    const createExportLink = (assoId, month, year) => {
        return (
            'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=vigne_export&asso_id=' +
            assoId +
            '&month=' +
            month +
            '&year=' +
            year
        );
    };

    const createDocumentLink = file => {
        if (file) {
            return (
                'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=download_document&filename=' +
                file.filename +
                '&id=' +
                file.id +
                '&obj=Association::' +
                file.item_id
            );
        }
    };

    const columns = [
        {
            name: 'Type',
            selector: row => row.type,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Nom',
            selector: row => row.filename.replace(row.type + '_', ''),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Date chargement',
            selector: row => <span>{moment(row.creation_date).format('DD-MM-YYYY')}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Télécharger',
            selector: row => {
                return (
                    <a className='list-table-link' href={createDocumentLink(row)} target='_blank'>
                        Télécharger
                    </a>
                );
            },
            filterable: true,
            sortable: true,
        },
    ];

    if (currentUser.function == 'Jobers') {
        return (
            <div className='backend-container'>
                <Sidebar />
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4'>
                        <h1 className='list-container-title'>Exports comptables</h1>
                    </div>
                    <div className='list-flex-child'>
                        <span className='list-container-counter'>
                            {filteredItems && filteredItems.length}
                        </span>
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4 list-container-tabs'>
                        <Link to={'/compta'} className='profil-tabs'>
                            Prévisionnels
                        </Link>
                        <Link to={'/compta/dsn'} className='profil-tabs'>
                            Export DSN
                        </Link>
                        <Link to={'/compta/export'} className='profil-tabs'>
                            Export comptable
                        </Link>
                    </div>
                    <div className='list-container-searchbar searchbar-with-button'>
                        <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                        <input
                            id='search'
                            className='list-container-searchinput'
                            type='text'
                            placeholder='Rechercher'
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                        />
                    </div>
                    <div className='profil-container-wipbutton'>
                        <span className='list-container-addlabel'>
                            <a
                                onClick={() => {
                                    editMode === 0 ? setEditMode(1) : setEditMode(0);
                                }}>
                                +
                            </a>
                        </span>
                    </div>
                    {editMode == 1 && (
                        <div className='more_actions'>
                            <div className='more_actions_action' onClick={() => setEditMode(1)}>
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {editMode == 1 && (
                        <div className='more_actions'>
                            <div
                                className='more_actions_action'
                                onClick={() => {
                                    // downloadCSV(filteredItems);
                                }}>
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item'>Exporter</div>
                            </div>
                            <div className='more_actions_action' onClick={() => setEditMode(2)}>
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                {assoId > 0 && year > 0 && month != '' && month != '00' ? (
                                    <a
                                        className='more_actions_item'
                                        href={createExportLink(assoId, month, year)}>
                                        Générer
                                    </a>
                                ) : (
                                    <div className='more_actions_item disabled'>Générer</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className='list-flex-container'>
                    <div className='list-container-divider-export' />
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child'>
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Exports comptables mensuels
                        </p>
                    </div>
                </div>
                <Form
                    onSubmit={onSubmit}
                    fields={formData.fields}
                    errors={formData.errors}
                    validateOnSubmit={true}>
                    {loading == false && (
                        <div className='list-flex-container'>
                            <Input2
                                id='association'
                                label='Association'
                                type='select'
                                placeholder='Sélectionner une association'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                options={
                                    loading
                                        ? [{ value: '', label: 'Chargement...' }]
                                        : [
                                              {
                                                  value: '0',
                                                  label: '--- Choisir une association ---',
                                              },
                                              ...associations.map(asso => ({
                                                  value: asso.id,
                                                  label: asso.name,
                                              })),
                                          ]
                                }
                                events={{
                                    onChange: e => {
                                        setAssoId(e.value);
                                    },
                                }}
                            />
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'></div>
                            </div>
                        </div>
                    )}
                    <div className='list-flex-container'>
                        <Input2
                            id='year'
                            label='Année'
                            type='select'
                            placeholder='2022'
                            classes={{
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            }}
                            options={[
                                { value: '0', label: "----- Choisir l'année -----" },
                                { value: '2022', label: '2022' },
                                { value: '2023', label: '2023' },
                                { value: '2024', label: '2024' },
                                { value: '2025', label: '2025' },
                            ]}
                            events={{
                                onChange: e => {
                                    setYear(e.value);
                                },
                            }}
                        />
                        <div className='list-flex-child profil-flex-container'>
                            <div className='profil-flex-child'></div>
                        </div>
                    </div>
                    <div className='list-flex-container'>
                        <Input2
                            id='month'
                            label='Mois'
                            type='select'
                            placeholder='Janvier'
                            classes={{
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            }}
                            options={[
                                { value: '00', label: '' },
                                { value: 'January', label: 'Janvier' },
                                { value: 'February', label: 'Février' },
                                { value: 'March', label: 'Mars' },
                                { value: 'April', label: 'Avril' },
                                { value: 'May', label: 'Mai' },
                                { value: 'June', label: 'Juin' },
                                { value: 'July', label: 'Juillet' },
                                { value: 'August', label: 'Août' },
                                { value: 'September', label: 'Septembre' },
                                { value: 'October', label: 'Octobre' },
                                { value: 'November', label: 'Novembre' },
                                { value: 'December', label: 'Décembre' },
                            ]}
                            events={{
                                onChange: e => {
                                    setMonth(e.value);
                                },
                            }}
                        />
                        <div className='list-flex-child profil-flex-container'>
                            <div className='profil-flex-child'></div>
                        </div>
                    </div>
                </Form>
                <div className='list-flex-container'>
                    <div className='list-flex-child list-table-container flex-4'>
                        <DataTable
                            noDataComponent={
                                <span className='rdt_noresults'>Aucun export disponible.</span>
                            }
                            columns={columns}
                            data={filteredItems}
                            persistTableHead
                        />
                    </div>
                </div>
            </div>
        );
    }
}
