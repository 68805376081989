import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import CompanyDataService from '../../services/company.service';
import DocumentDataService from '../../services/document.service';

export default function AssociationDocumentList(props) {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const currentUser = useAuthState();
    const [entreprise, setEntreprise] = useState([]);
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const [editMode, setEditMode] = useState(0);
    const [year, setYear] = useState(0);
    const isAssociation = () => {
        if (currentUser.function === 'Compte association') {
            return true;
        }
        if (currentUser.function === 'Président') {
            return true;
        }
        return false;
    };

    useEffect(() => {
        let objectData = {
            id: null,
            name: 'Nom non communiqué',
        };
        let key = null;
        if (id !== '0' && id !== 'me') {
            key = id;
        }
        if (currentUser.id !== '0' && id == 'me') {
            key =
                'SELECT Company AS C JOIN Person AS P ON P.org_id = C.id WHERE P.id = ' +
                currentUser.id;
        }
        if (key) {
            CompanyDataService.get(key).then(response => {
                objectData = {
                    ...objectData,
                    ...response,
                };

                setEntreprise(objectData);

                key =
                    'SELECT DocumentNote AS DN WHERE DN.documenttype_name IN ("Facture", "Convention d\'études") AND DN.org_id = ' +
                    objectData.id;

                if (isAssociation()) {
                    key =
                        'SELECT DocumentNote AS DN JOIN Mission AS M ON DN.mission_id = M.id WHERE DN.documenttype_name IN ("Facture", "Convention d\'études", "Acceptation de mission") AND DN.org_id = ' +
                        objectData.id +
                        ' AND M.student_org_id = ' +
                        currentUser.org_id;
                }

                if (year > 0) {
                    key += ' AND YEAR(DN.created_at) = ' + year;
                }

                const fields = 'id, name, org_name, documenttype_name, status, description';

                DocumentDataService.getAll(key, fields).then(response => {
                    setData(response);
                });
            });
        }
    }, [year]);

    const displayDocumentType = row => {
        switch (row.documenttype_name) {
            case 'Facture':
                return 'Facture';
            case 'Bon de versement':
                return 'Bon de versement';
            case "Convention d'études":
                return 'Convention';
            case 'Acceptation de mission':
                return 'Acceptation de mission';
            default:
                return 'Non communiqué';
        }
    };

    const displayDocumentLink = row => {
        switch (row.documenttype_name) {
            case "Convention d'études":
                return (
                    <a
                        className='list-table-link'
                        href={'https://itop.jobers.fr/pdf_c.php?mission_id=' + row.mission_id}
                        target='_blank'>
                        <div className='icon-size'>
                            <img className='icon-img' alt='go-back' src='/img/download.png' />
                        </div>
                    </a>
                );
            case 'Facture':
                return (
                    <a
                        className='list-table-link'
                        href={'https://itop.jobers.fr/pdf_f.php?facture_id=' + row.id}
                        target='_blank'>
                        <div className='icon-size'>
                            <img className='icon-img' alt='go-back' src='/img/download.png' />
                        </div>
                    </a>
                );
            case 'Acceptation de mission':
                return (
                    <a
                        className='list-table-link'
                        href={'https://itop.jobers.fr/pdf_am.php?mission_id=' + row.mission_id}
                        target='_blank'>
                        <div className='icon-size'>
                            <img className='icon-img' alt='go-back' src='/img/download.png' />
                        </div>
                    </a>
                );
        }
    };

    const convertArrayOfObjectsToCSV = array => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(filteredItems[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    const columns = [
        {
            name: 'Document',
            selector: row => displayDocumentType(row),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.documenttype_name < b.documenttype_name) {
                    return -1;
                }
                if (a.documenttype_name > b.documenttype_name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Référence',
            selector: row => row.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Type mission',
            selector: row =>
                row.mission_type === 'normal'
                    ? 'Convention temps complet'
                    : row.mission_type === 'parttime'
                    ? 'Convention temps partiel'
                    : 'Convention HMONP',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => (
                <span>
                    {moment(row.created_at).format('DD-MM-')}
                    {(year == '0' || moment(row.created_at).format('YYYY') != year) && (
                        <span>{moment(row.created_at).format('YYYY')}</span>
                    )}
                    {year > 0 && moment(row.created_at).format('YYYY') == year && (
                        <span className='bold'>{moment(row.created_at).format('YYYY')}</span>
                    )}
                </span>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.created_at < b.created_at) {
                    return -1;
                }
                if (a.created_at > b.created_at) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Télécharger',
            selector: row => displayDocumentLink(row),
            filterable: true,
            sortable: true,
        },
    ];

    if (Object.keys(entreprise).length === 0) {
        return (
            <div class='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <div
            className='backend-container'
            onClick={() => {
                if (editMode === 1) {
                    setEditMode(0);
                }
            }}>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>{entreprise && entreprise.name}</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4 list-container-tabs'>
                    <Link to={'/entreprise/' + id + '/profil'} className='profil-tabs'>
                        Profil
                    </Link>
                    <Link to={'/entreprise/' + id + '/mission'} className='profil-tabs'>
                        Missions
                    </Link>
                    <Link to={'/entreprise/' + id + '/document'} className='profil-tabs'>
                        Documents
                    </Link>
                </div>
                <div className='list-container-searchbar searchbar-with-button'>
                    <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                    <input
                        id='search'
                        className='list-container-searchinput'
                        type='text'
                        placeholder='Rechercher'
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                    />
                </div>
                {editMode < 2 && (
                    <div className='profil-container-wipbutton'>
                        <span className='list-container-addlabel'>
                            <a
                                onClick={() => {
                                    editMode === 1 ? setEditMode(0) : setEditMode(1);
                                }}>
                                +
                            </a>
                        </span>
                    </div>
                )}
                {editMode == 1 && (
                    <div className='more_actions'>
                        <div
                            className='more_actions_action'
                            onClick={() => {
                                downloadCSV(filteredItems);
                            }}>
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <div className='more_actions_item'>Exporter</div>
                        </div>
                        <div className='more_actions_action' onClick={() => setEditMode(2)}>
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <div className='more_actions_item disabled'>Filtrer</div>
                        </div>
                    </div>
                )}
            </div>
            <div className='list-flex-container'>
                <div className='profil-container-divider-3' />
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    <p className='list-container-subtitle list-tab-subtitle'>
                        Tous les documents liés à vos missions passées ou en cours.
                    </p>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child profil-flex-container'>
                    <div className='profil-flex-child'>
                        <div className='profil-label'>
                            <label htmlFor='year'>Année</label>
                        </div>
                    </div>
                    <div className='profil-flex-child'>
                        <select
                            id='year'
                            className='profil-value'
                            onChange={e => setYear(e.target.value)}>
                            <option value='0'>---- Choisir une année ----</option>
                            <option value='2022'>2022</option>
                            <option value='2023'>2023</option>
                            <option value='2024'>2024</option>
                            <option value='2025'>2025</option>
                        </select>
                    </div>
                </div>
                <div className='list-flex-child profil-flex-container'>
                    <div className='profil-flex-child'></div>
                    <div className='profil-flex-child'></div>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucun document trouvé.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    );
}
