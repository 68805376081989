import './RegisterStudentForm.css';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';
import Form from '../Utils/Form';
import Input from '../Utils/Input';
import SelectCountry from '../Utils/SelectCountry';
import { useAuthState } from '../../context';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

export default function RegisterStudentForm(props) {
    const StudentContext = createContext();
    const [submitted, setSubmitted] = useState(0);
    const [id, setId] = useState(false);
    const currentUser = useAuthState();
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const [publicKey, setPublicKey] = useState(false);
    const [assoName, setAssoName] = useState(false);

    const sendFiles = async (files, filename) => {
        let data = false;
        let b64 = false;
        let attachment = false;
        let operation = 'add_student_attachment';

        if (files.length === 0) {
            operation = 'remove_student_attachment';
            attachment = {
                item_id: id,
                temp_id: filename,
            };
        }

        if (files.length > 0) {
            b64 = await convertBase64(files[0]);
            filename = filename + files[0].name.split('.').pop();
            data = b64.substring(b64.indexOf('base64,') + 7);
            attachment = {
                item_class: 'Person',
                item_id: id,
                temp_id: filename,
                item_org_id: 'SELECT Organization WHERE name = "Jobers"',
                creation_date: new Date(),
                contents: {
                    data: data,
                    filename: filename,
                    mimetype: files[0].type,
                },
            };
        }

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(attachment));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const updateSchoolCertificate = async files => {
        await sendFiles(files, 'Certificat de Scolarité.');
        // setFormData({ ...formData, school_certificate: files });
    };

    const updateIdentityCard = async files => {
        await sendFiles(files, 'Pièce d’identité.');
        // setFormData({ ...formData, identity_card: files });
    };

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    useEffect(() => {
        if (currentUser && currentUser.email == 'alan.badier@gmail.com') {
            setId(1);
            setSubmitted(2);
        }
    }, []);

    const onSubmit = async (fields, errors) => {
        let data = false;
        let config = false;
        let response = false;

        switch (submitted) {
            case 0:
                let dataFields = {
                    id: id,
                    last_name: fields.last_name.value,
                    first_name: fields.first_name.value,
                    email: fields.email.value,
                    phone: fields.phonenumber.value,
                    nationality: fields.nationality.value,
                    location_name: fields.first_name.value + ' ' + fields.last_name.value,
                    password: fields.password.value,
                    address: fields.address.value,
                    postal_code: fields.postcode.value,
                    city: fields.city.value,
                    country: fields.country.value,
                };

                data = new URLSearchParams();
                data.append('operation', 'create_student');
                data.append('data', JSON.stringify(dataFields));

                config = {
                    method: 'post',
                    url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json',
                    },
                    data: data,
                };

                response = await axios(config);

                if (response.data.code !== 0) {
                    console.log(response.data.message);
                }

                if (response.data.code === 0 && submitted < 2) {
                    setId(response.data.key);
                    setSubmitted(submitted + 1);
                }
                break;
            case 1:
                let assoName = fields.school.value != '' ? fields.school.value.split(' - ')[0] : '';

                if (fields.otherschool.value != '') {
                    assoName = fields.otherschool.value;
                }

                let schoolFields = {
                    id: id,
                    school_year:
                        fields.school_year.value == ''
                            ? fields.school_year.defaultValue
                            : fields.school_year.value,
                    year: fields.year.value,
                    org_id: assoName,
                };

                data = new URLSearchParams();
                data.append('operation', 'set_school_informations');
                data.append('data', JSON.stringify(schoolFields));

                config = {
                    method: 'post',
                    url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json',
                    },
                    data: data,
                };

                response = await axios(config);

                if (response.data.code !== 0) {
                    console.log(response.data.message);
                }

                if (response.data.code === 0 && response.data.key !== 1) {
                    if (response.data.pk_stripe !== '') {
                        setPublicKey(response.data.pk_stripe);
                        setAssoName(assoName);
                        setSubmitted(submitted + 1);
                    }
                    if (response.data.pk_stripe === '') {
                        setSubmitted(99);
                    }
                }

                if (response.data.code === 0 && response.data.key == 1) {
                    setSubmitted(99);
                }
                break;
            case 2:
                let bankFields = {
                    id: id,
                    bank: fields.bank.value,
                    // remove spaces from iban
                    iban: fields.iban.value.replace(/\s/g, ''),
                    bic: fields.bic.value,
                    ssn: fields.ssn.value,
                };

                data = new URLSearchParams();
                data.append('operation', 'set_bank_informations');
                data.append('data', JSON.stringify(bankFields));

                config = {
                    method: 'post',
                    url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json',
                    },
                    data: data,
                };

                response = await axios(config);

                if (response.data.code === 0) {
                    setSubmitted(submitted + 1);
                }

                if (response.data.code !== 0) {
                    console.log(response.data.message);
                }

                break;
            case 3:
                break;
            case 4:
                break;
            default:
                break;
        }

        setFormData({
            fields,
            errors,
        });
    };

    const RegisterStudentForm = props => {
        const { fields } = useContext(StudentContext);

        return (
            <div className='register-student screen'>
                <div className='wizard-steps'>
                    {/* <Link to='/register'>
                        <img
                            className='arrow-left'
                            alt='go-back'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3dde03eda8a1cb03a287a/img/arrow-left@2x.svg'
                        />
                    </Link> */}
                    <div className='progress-steps-progress-icons-centered'>
                        <div className='list-steps'>
                            <div className='overlap-group'>
                                <div className='lines'>
                                    <div className='line'></div>
                                    <div className='line'></div>
                                    <div className='line'></div>
                                </div>
                                <div className='step-base'>
                                    <div className='step-icon-base-1'>
                                        <div className='step-dot-filled'>
                                            <div className='dot'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Informations personnelles
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-1'>
                                    <div className='step-icon-base'>
                                        <div className='step-content-dot'>
                                            <div className='dot'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Informations d’études
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-2'>
                                    <div className='step-icon-base'>
                                        <div className='step-content-dot'>
                                            <div className='dot'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Justificatifs
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-3'>
                                    <div className='step-icon-base'>
                                        <div className='step-content-dot'>
                                            <div className='dot'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Adhésion
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='register-student-title'>Informations personnelles</div>
                <div className='register-student-form'>
                    <Form className='form' onSubmit={onSubmit} validateOnSubmit={true}>
                        <div className='contact-panel'>
                            <div className='contact-title'>Informations de connexion</div>
                            <div className='login-divider'></div>
                        </div>
                        <Input
                            id='email'
                            label='Adresse email'
                            placeholder='contact@etu.fr'
                            validate='required|email'
                            value={typeof fields.email === 'undefined' ? '' : fields.email.value}
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='password'
                            label='Mot de passe'
                            placeholder='•••••••••'
                            validate='password'
                            value={
                                typeof fields.password === 'undefined' ? '' : fields.password.value
                            }
                            type='password'
                            hintmessage='Doit être au moins de 8 caractères et avoir un caractère spécial (@$!%*#?&)'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass: 'input-box inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='confirm_password'
                            name='confirm_password'
                            label='Confirmez mot de passe'
                            placeholder='•••••••••'
                            type='password'
                            validate='required|confirm_password'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass: 'input-box inter-normal-pale-sky-16px',
                            }}
                        />
                        <div className='contact-panel'>
                            <div className='contact-title'>Nom et adresse</div>
                            <div className='login-divider'></div>
                        </div>
                        <Input
                            id='last_name'
                            label='Nom'
                            placeholder='Doe'
                            validate='required'
                            value={
                                typeof fields.last_name === 'undefined'
                                    ? ''
                                    : fields.last_name.value
                            }
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='first_name'
                            label='Prénom'
                            placeholder='John'
                            validate='required'
                            value={
                                typeof fields.first_name === 'undefined'
                                    ? ''
                                    : fields.first_name.value
                            }
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <SelectCountry
                            id='nationality'
                            label='Nationalité'
                            validate='required'
                            value={
                                typeof fields.nationality === 'undefined'
                                    ? 'France'
                                    : fields.nationality.value
                            }
                            defaultValue='France'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass: 'input-box border-1px-mischka inter-normal-mirage-16px',
                            }}
                        />
                        <Input
                            id='phonenumber'
                            label='Numéro de téléphone'
                            placeholder='+33 6 11 22 33 44'
                            validate='required|phone'
                            value={
                                typeof fields.phonenumber === 'undefined'
                                    ? ''
                                    : fields.phonenumber.value
                            }
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <div className='contact-container'>
                            <Input
                                id='address'
                                label='Adresse'
                                placeholder='Place de l’Hôtel-de-Ville'
                                validate='required'
                                value={
                                    typeof fields.address === 'undefined'
                                        ? ''
                                        : fields.address.value
                                }
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <div className='div-2-col'>
                                <Input
                                    id='city'
                                    label='Ville'
                                    placeholder='Paris'
                                    validate='required'
                                    value={
                                        typeof fields.city === 'undefined' ? '' : fields.city.value
                                    }
                                    classes={{
                                        contClass: 'input-col-1',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-1col border-1px-mischka inter-normal-mirage-16px',
                                    }}
                                />
                                <Input
                                    id='postcode'
                                    label='Code postal'
                                    placeholder='75004'
                                    validate='required'
                                    value={
                                        typeof fields.postcode === 'undefined'
                                            ? ''
                                            : fields.postcode.value
                                    }
                                    classes={{
                                        contClass: 'input-col-1',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-1col border-1px-mischka inter-normal-mirage-16px',
                                    }}
                                />
                            </div>
                        </div>
                        <SelectCountry
                            id='country'
                            label='Pays'
                            validate='required'
                            value={
                                typeof fields.country === 'undefined'
                                    ? 'France'
                                    : fields.country.value
                            }
                            defaultValue='France'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass: 'input-box border-1px-mischka inter-normal-mirage-16px',
                            }}
                        />
                        <div className='checkbox-group-item'>
                            <div className='checkbox'>
                                <div className='checkbox-1'>
                                    <input
                                        type='checkbox'
                                        className='checkbox-base checkbox-round border-1px-mischka'
                                        id='accept_checkbox'
                                    />
                                </div>
                                <label
                                    className='inter-medium-oxford-blue-14px'
                                    htmlFor='accept_checkbox'>
                                    J’accepte la gestion et le traitements de mes informations.
                                </label>
                            </div>
                        </div>
                        <div className='bottom-right-actions'>
                            <div className='bottom-right-actions-button-base'>
                                <Link to='/'>
                                    <button
                                        type='submit'
                                        className='border-1px-mischka bottom-right-actions-button bottom-right-button-text inter-medium-oxford-blue-14px'>
                                        Étape précédente
                                    </button>
                                </Link>
                            </div>
                            <div className='bottom-right-actions-button-base'>
                                <div className='bottom-right-actions-button'>
                                    <button
                                        type='submit'
                                        className='validation-button bottom-right-button-text bottom-right-button-submit'>
                                        Étape suivante
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    };

    const RegisterStudentFormSchool = props => {
        const { fields } = useContext(StudentContext);

        return (
            <div className='register-student screen'>
                <div className='wizard-steps'>
                    {/* <img
                        className='arrow-left'
                        alt='arrow-left'
                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3dde03eda8a1cb03a287a/img/arrow-left@2x.svg'
                    /> */}
                    <div className='progress-steps-progress-icons-centered'>
                        <div className='content-3'>
                            <div className='overlap-group'>
                                <div className='lines'>
                                    <div className='line-1'></div>
                                    <div className='line'></div>
                                    <div className='line'></div>
                                </div>
                                <div className='step-base'>
                                    <img
                                        className='step-icon-base-1'
                                        alt='step-icon-base-1'
                                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f66473f593149d8b2ce888/img/-step-icon-base@2x.svg'
                                    />
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Informations personnelles
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-1'>
                                    <div className='step-icon-base-2'>
                                        <div className='step-dot-filled'>
                                            <div className='dot'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='text-2 inter-medium-oxford-blue-14px'>
                                            Informations d’études
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-2'>
                                    <div className='step-icon-base'>
                                        <div className='content-1'>
                                            <div className='dot-1'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Justificatifs
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-3'>
                                    <div className='step-icon-base'>
                                        <div className='content-1'>
                                            <div className='dot-2'></div>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Adhésion
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='register-student-title'>Informations d’études</div>
                <div className='register-student-form'>
                    <Form
                        className='form'
                        onSubmit={onSubmit}
                        validateOnSubmit={true}
                        validate={true}>
                        <Input
                            id='year'
                            label='Année scolaire'
                            disabled='disabled'
                            value='2024-2025'
                            validate='required'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box input-disabled border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='school_year'
                            label='Niveau études'
                            value={
                                typeof fields.school_year === 'undefined'
                                    ? '1'
                                    : fields.school_year.value
                            }
                            type='select'
                            options={[
                                { value: '1', label: 'Première année' },
                                { value: '2', label: 'Deuxième année' },
                                { value: '3', label: 'Troisième année' },
                                { value: '4', label: 'Quatrième année' },
                                { value: '5', label: 'Cinquième année' },
                                { value: 'HMONP', label: 'Sixième année' },
                            ]}
                            validate='required'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='school'
                            label='Mon association ou école'
                            placeholder='Nom de l’association ou école'
                            validate='school_required'
                            type='select'
                            name='school'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                            value={typeof fields.school === 'undefined' ? '' : fields.school.value}
                            options={[
                                { value: '', label: 'Veuillez choisir votre école' },
                                { value: 'AERESA - Paris ESA', label: 'AERESA - Paris ESA' },
                                {
                                    value: 'TESAA - ENSA Strasbourg',
                                    label: 'TESAA - ENSA Strasbourg',
                                },
                                {
                                    value: 'ATONIC - ENSA Versailles',
                                    label: 'ATONIC - ENSA Versailles',
                                },
                                {
                                    value: 'ARCHI’MADE - ENSA Clermont-Ferrand',
                                    label: 'ARCHI’MADE - ENSA Clermont-Ferrand',
                                },
                                { value: 'CUTCH - ENSA Grenoble', label: 'CUTCH - ENSA Grenoble' },
                                { value: 'ARCHIPEL - ENSA Lille', label: 'ARCHIPEL - ENSA Lille' },
                                { value: 'JCTPE - ENSA Lyon', label: 'JCTPE - ENSA Lyon' },
                                {
                                    value: 'PREALAB - ENSA Marne la Vallée',
                                    label: 'PREALAB - ENSA Marne la Vallée',
                                },
                                {
                                    value: 'GRAINE D’ARCHI - ENSA Marseille',
                                    label: 'GRAINE D’ARCHI - ENSA Marseille',
                                },
                                {
                                    value: 'ARCHIPEL - ENSA Montpellier',
                                    label: 'ARCHIPEL - ENSA Montpellier',
                                },
                                {
                                    value: 'ESQUISSE - ENSA Nantes',
                                    label: 'ESQUISSE - ENSA Nantes',
                                },
                                {
                                    value: 'ASSO B - ENSA Paris Belleville',
                                    label: 'ASSO B - ENSA Paris Belleville',
                                },
                                {
                                    value: 'WIP - ENSA Paris Malaquais',
                                    label: 'WIP - ENSA Paris Malaquais',
                                },
                                {
                                    value: 'JAUB - ENSA Paris Val de Seine',
                                    label: 'JAUB - ENSA Paris Val de Seine',
                                },
                                {
                                    value: 'PASSERELLE V - ENSA Paris Villette',
                                    label: 'PASSERELLE V - ENSA Paris Villette',
                                },
                                {
                                    value: 'TRAIT D’UNION - ENSA Rennes',
                                    label: 'TRAIT D’UNION - ENSA Rennes',
                                },
                                { value: 'JAFAAR - ENSA Rouen', label: 'JAFAAR - ENSA Rouen' },
                                {
                                    value: 'VILLEBREQUIN - ENSA St Etienne',
                                    label: 'VILLEBREQUIN - ENSA St Etienne',
                                },
                                {
                                    value: 'RHIZOME - ENSA Toulouse',
                                    label: 'RHIZOME - ENSA Toulouse',
                                },
                            ]}
                        />
                        <Input
                            id='otherschool'
                            label='Autre'
                            hintmessage="Si l'association de votre école n'est pas dans la liste ci-dessus, veuillez renseigner son nom et votre ville ici."
                            value=''
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <div className='bottom-right-actions'>
                            <div className='bottom-right-actions-button-base'>
                                <button
                                    type='button'
                                    onClick={() => {
                                        setSubmitted(submitted - 1);
                                    }}
                                    className='border-1px-mischka bottom-right-actions-button bottom-right-button-text inter-medium-oxford-blue-14px'>
                                    Étape précédente
                                </button>
                            </div>
                            <div className='bottom-right-actions-button-base'>
                                <div className='bottom-right-actions-button'>
                                    <button
                                        type='submit'
                                        className='validation-button bottom-right-button-text bottom-right-button-submit'>
                                        Étape suivante
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    };

    const RegisterStudentFormAttachments = props => {
        return (
            <div className='register-student screen'>
                <div className='wizard-steps'>
                    {/* <img
                        className='arrow-left'
                        alt='arrow-left'
                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3dde03eda8a1cb03a287a/img/arrow-left@2x.svg'
                    /> */}
                    <div className='progress-steps-progress-icons-centered'>
                        <div className='list-steps'>
                            <div className='overlap-group'>
                                <div className='lines'>
                                    <div className='line'></div>
                                    <div className='line'></div>
                                    <div className='line-1'></div>
                                </div>
                                <div className='step-base'>
                                    <img
                                        className='step-icon-base'
                                        alt='step-icon-base'
                                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f66473f593149d8b2ce888/img/-step-icon-base@2x.svg'
                                    />
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Informations personnelles
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-1'>
                                    <img
                                        className='step-icon-base'
                                        alt='step-icon-base'
                                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f66473f593149d8b2ce888/img/-step-icon-base@2x.svg'
                                    />
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Informations d’études
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-2'>
                                    <div className='step-icon-base-1'>
                                        <div className='step-dot-filled'>
                                            <div className='dot'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-gable-green-14px'>
                                            Justificatifs
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-3'>
                                    <div className='step-icon-base-2'>
                                        <div className='step-content-dot'>
                                            <div className='dot'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Adhésion
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='register-student-title'>Justificatifs</div>
                <div className='register-student-form'>
                    <Form
                        className='form'
                        onSubmit={onSubmit}
                        validate={true}
                        validateOnSubmit={true}>
                        <Input
                            accept='.jpg,.png,.jpeg,.pdf'
                            label='Certificat de scolarité'
                            updateFilesCb={updateSchoolCertificate}
                            validate='required'
                            id='school_certificate'
                            type='file'
                            classes={{
                                errorClass: 'error-text',
                            }}
                            files={formData.school_certificate}
                        />
                        <Input
                            accept='.jpg,.png,.jpeg,.pdf'
                            label='Pièce d’identité valide'
                            updateFilesCb={updateIdentityCard}
                            validate='required'
                            id='identity_card'
                            type='file'
                            classes={{
                                errorClass: 'error-text',
                            }}
                            files={formData.identity_card}
                        />
                        <Input
                            id='ssn'
                            label='Numéro de sécurité sociale'
                            placeholder='126237893242424'
                            validate='required|ssn'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='bank'
                            label='Banque'
                            placeholder='Société générale'
                            validate='required'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='iban'
                            label='IBAN'
                            placeholder='FR74 3233 4242 4244 2332 324'
                            validate='required|iban'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='bic'
                            label='BIC'
                            placeholder='632839232 32'
                            validate='required|bic'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <div className='bottom-right-actions'>
                            <div className='bottom-right-actions-button-base'>
                                <button
                                    type='button'
                                    onClick={() => {
                                        setSubmitted(submitted - 1);
                                    }}
                                    className='border-1px-mischka bottom-right-actions-button bottom-right-button-text inter-medium-oxford-blue-14px'>
                                    Étape précédente
                                </button>
                            </div>
                            <div className='bottom-right-actions-button-base'>
                                <div className='bottom-right-actions-button'>
                                    <button
                                        type='submit'
                                        className='validation-button bottom-right-button-text bottom-right-button-submit'>
                                        Étape suivante
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    };

    const CheckoutForm = props => {
        const stripe = useStripe();
        const elements = useElements();
        const [message, setMessage] = useState(null);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            if (!stripe) {
                return;
            }

            const clientSecret = new URLSearchParams(window.location.search).get(
                'payment_intent_client_secret'
            );

            if (!clientSecret) {
                return;
            }

            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                switch (paymentIntent.status) {
                    case 'succeeded':
                        setMessage('Payment succeeded!');
                        break;
                    case 'processing':
                        setMessage('Your payment is processing.');
                        break;
                    case 'requires_payment_method':
                        setMessage('Your payment was not successful, please try again.');
                        break;
                    default:
                        setMessage('Something went wrong.');
                        break;
                }
            });
        }, [stripe]);

        const handleSubmit = async e => {
            e.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            setIsLoading(true);

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url:
                        'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=validate_stripe_payment&person_id=' +
                        id,
                },
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === 'card_error' || error.type === 'validation_error') {
                setMessage(error.message);
            } else {
                setMessage('An unexpected error occurred.');
            }

            setIsLoading(false);
        };

        const paymentElementOptions = {
            layout: 'tabs',
        };

        return (
            <form id='payment-form' onSubmit={handleSubmit}>
                <PaymentElement id='payment-element' options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id='submit'>
                    <span id='button-text'>
                        {isLoading ? (
                            <div class='lds-roller'>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <div className='bottom-right-actions payment-submit'>
                                <div className='bottom-right-actions-button-base'>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            // setSubmitted(submitted + 1);
                                        }}
                                        className='validation-button bottom-right-button-text bottom-right-button-submit'>
                                        Payer et finaliser mon inscription
                                    </button>
                                </div>
                            </div>
                        )}
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && <p className='error-text'>{message}</p>}
            </form>
        );
    };

    const RegisterStudentFormPending = () => {
        return (
            <div className='register-organization-submit screen'>
                <div className='content'>
                    <div className='header'>
                        <Logo />
                        <div className='text-and-supporting-text'>
                            <div className='text'>
                                Nous allons entrer en contact avec votre école
                            </div>
                            <p className='supporting-text'>
                                Nous reviendrons prochainement vers vous pour vous donner des
                                nouvelles sur le sujet.
                            </p>
                        </div>
                    </div>
                    <Link to='/'>
                        <button type='submit' className='primary-submit'>
                            Revenir à l'accueil
                        </button>
                    </Link>
                </div>
            </div>
        );
    };

    const RegisterStudentFormStripe = props => {
        const stripePromise = loadStripe(publicKey);
        const [clientSecret, setClientSecret] = useState(false);

        useEffect(() => {
            const fetchClientSecret = async () => {
                const data = new URLSearchParams();
                data.append('operation', 'create_stripe_indent');
                data.append('public_key', publicKey);

                let config = {
                    method: 'post',
                    url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json',
                    },
                    data: data,
                };

                await axios(config).then(response => {
                    setClientSecret(response.data.clientSecret);
                });
            };

            if (!clientSecret) {
                fetchClientSecret().catch(console.error);
            }
        }, []);

        const appearance = {
            theme: 'stripe',
            variables: {
                colorPrimary: '#005f61',
                colorText: '#41467c',
            },
        };
        const options = {
            clientSecret,
            appearance,
        };

        return (
            <div className='register-student screen'>
                <div className='wizard-steps'>
                    <div className='progress-steps-progress-icons-centered'>
                        <div className='list-steps'>
                            <div className='overlap-group'>
                                <div className='lines'>
                                    <div className='line'></div>
                                    <div className='line'></div>
                                    <div className='line'></div>
                                </div>
                                <div className='step-base'>
                                    <img
                                        className='step-icon-base'
                                        alt='step-icon-base'
                                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f66473f593149d8b2ce888/img/-step-icon-base@2x.svg'
                                    />
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Informations personnelles
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-1'>
                                    <img
                                        className='step-icon-base'
                                        alt='step-icon-base'
                                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f66473f593149d8b2ce888/img/-step-icon-base@2x.svg'
                                    />
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Informations d’études
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-2'>
                                    <img
                                        className='step-icon-base'
                                        alt='step-icon-base'
                                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f66473f593149d8b2ce888/img/-step-icon-base@2x.svg'
                                    />
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Justificatifs
                                        </div>
                                    </div>
                                </div>
                                <div className='step-base-3'>
                                    <div className='step-icon-base-1'>
                                        <div className='step-dot-filled'>
                                            <div className='dot'></div>
                                        </div>
                                    </div>
                                    <div className='step-content'>
                                        <div className='step-text inter-medium-oxford-blue-14px'>
                                            Adhésion
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Link to='/register'>
                        <img
                            className='arrow-left'
                            alt='go-back'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3dde03eda8a1cb03a287a/img/arrow-left@2x.svg'
                        />
                    </Link> */}
                </div>
                <div className='register-student-title'>Adhésion à une association</div>
                <div className='register-student-form'>
                    <Form className='form' onSubmit={onSubmit} validateOnSubmit={true}>
                        <Input
                            id='year'
                            label='Vous allez adhérer à'
                            disabled='disabled'
                            value={assoName}
                            validate='required'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box input-disabled border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <div className='text-and-supporting-text'>
                            <p className='stripe-infos inter-normal-oxford-blue-14px'>
                                <span className='inter-normal-oxford-blue-14px'>
                                    Le montant de l'adhésion à votre association est de
                                </span>
                                <span className='inter-medium-oxford-blue-14px'>&nbsp;</span>
                                <span className='stripe-price'>10€/an </span>
                            </p>
                        </div>
                    </Form>
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    )}
                </div>
                <div className='bottom-right-actions payment-cancel'>
                    <div className='bottom-right-actions-button-base'>
                        <button
                            type='button'
                            onClick={() => {
                                setSubmitted(submitted - 1);
                            }}
                            className='border-1px-mischka bottom-right-actions-button bottom-right-button-text inter-medium-oxford-blue-14px'>
                            Étape précédente
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <StudentContext.Provider value={formData}>
            <div className='container-center-horizontal'>
                {submitted === 0 && <RegisterStudentForm />}
                {submitted === 1 && <RegisterStudentFormSchool />}
                {submitted === 2 && <RegisterStudentFormAttachments />}
                {submitted === 3 && <RegisterStudentFormStripe />}
                {submitted === 99 && <RegisterStudentFormPending />}
            </div>
        </StudentContext.Provider>
    );
}
